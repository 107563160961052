import {useState, useContext, useCallback} from "react";
import {sortWordsArray} from "../../helpers/sortWordsArray";
import {DictionaryContext} from "../../contexts/dictionary";

import "swiper/css";
import './header-select.scss';
import {Mousewheel} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

const HeaderSelect = ({toggleState, resetState}) => {
    const {
        state: {search: {theme}, data: {themes}},
        updateTheme
    } = useContext(DictionaryContext);
    const [isOpen, setIsOpen] = useState(false);

    const themeAll = {
        _id: '',
        type: 'all',
        label: {
            fr: 'tout',
            en: 'all'
        }
    }
    const themesSorted = (sortWordsArray(themes)).concat(themeAll);
    const getActiveIndex = useCallback(() => {
        return themesSorted.findIndex((t) => t._id === theme._id);
    }, [theme, themesSorted]);

    const handleDropdown = () => {
        setIsOpen(!isOpen);
    }

    const getThemeName = useCallback(() =>
        `Themes${theme._id === '' ? '' : ` > ${theme.label.fr}`}`, [theme]
    );

    const renderThemes = () => {
        if (themes.length) {
            return themesSorted.map((theme) => {
                return <SwiperSlide key={`theme_${theme._id}`}>
                    <div
                    className={"dropdown__item"}
                    onClick={() => {
                        updateTheme(theme);
                        handleDropdown();
                        resetState();
                    }}
                >
                    {theme.label.fr}
                    </div>
                </SwiperSlide>;
            });
        }

        return null;
    };

    return <div className={"dropdown"}>
        <div className={`dropdown__themes ${isOpen ? 'dropdown--open' : 'dropdown--close'}`}>
            <Swiper
                direction={"vertical"}
                slidesPerView={5}
                loop={true}
                mousewheel={true}
                modules={[Mousewheel]}
                centeredSlides={true}
                initialSlide={getActiveIndex()}
            >
                {renderThemes()}
            </Swiper>
        </div>
        <div
            className={`dropdown__current ${isOpen ? 'dropdown--close' : 'dropdown--open'}`}
            onClick={() => {
                handleDropdown();
                toggleState();
            }}
        >
            {getThemeName()}
        </div>
    </div>;
}

export default HeaderSelect;
