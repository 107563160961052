import 'react-toastify/dist/ReactToastify.min.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root from "./features/root";
import Manage from "./features/manage";
import Home from "./features/home";
import {DictionaryContextProvider} from "./contexts/dictionary";
import {StateInspector} from "reinspect";

function App() {
    // const {showReload, waitingWorker, reloadPage} = useServiceWorker();

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            children: [
                {
                    path: '',
                    element: <Home />
                    // element: <div>Coucou</div>
                }, {
                    path:'manage',
                    element: <Manage />
                }
            ]
        }
    ]);

    return <>
        <StateInspector name={"DictionaryState"}>
            <DictionaryContextProvider>
                <RouterProvider router={router} />
            </DictionaryContextProvider>
        </StateInspector>
    </>;
}

export default App;
