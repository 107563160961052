import {Outlet} from "react-router-dom";
import Header from "../components/header/header";
import {ToastContainer} from "react-toastify";

const Root = () => {
    return <>
        <Header />
        <main>
            <Outlet />
        </main>
        <ToastContainer/>
    </>
};

export default Root;
