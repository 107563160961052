import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";

const AddWord = ({themes, subthemes, types}) => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}} = useForm({
        defaultValues: {
            theme: null,
            subtheme: null,
            type: null,
            translation: {
                word: undefined,
                hiragana: undefined,
                romaji: undefined
            }
        }
    });

    const [themeId, setThemeId] = useState(undefined);
    const [label, setLabel] = useState(undefined);
    const onSubmit = async (data) => {
        console.log(getValues('theme'));
        console.table(data);

        try {
            const res = await (await fetch(`${process.env.REACT_APP_BACKEND_URL}/words/add`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                mode: 'cors',
                body: JSON.stringify({
                    word: data
                })
            })).json();
            console.log('res', res);
        } catch(e) {
            throw new Error(e);
        }
    };

    // Check for existing languages, then add X property in labels object
    const setLabelLangs = useCallback(() => {
        const data = {};
        for (const lang in langs) {
            data[langs[lang]] = ''
        }

        return data;
    }, [],);

    // remove this when the language system will be ready
    const langs = ['fr', 'en'];

    useEffect(() => {
        const data = setLabelLangs();

        if (!label) {
            setLabel(data);
        }
    }, []);

    const themeChangeHandle = useCallback((e) => {
        setValue('theme', e.target.value);
        setValue('subtheme', undefined);
        setThemeId(e.target.value);
    }, [setValue, setThemeId]);

    const renderLabelsInput = useCallback(() => {
        if (!label) {
            return null;
        }
        const keys = Object.keys(label);

        return keys.map((k) => {
            return <div key={`label--${k}`}>
                <input placeholder={k.toUpperCase()} type="text" {...register(`label[${k}]`, {required: true})} />
            </div>;
        });
    }, [label]);

    const renderRadioInputs = useCallback((name, data, changeHandle = undefined) => {
        return data.map((item) => {
            return <div key={`radio-value-${name}-${item._id}`}>
                <input id={`radio-${name}-${item._id}`} type="radio"
                       value={item._id}
                       {...register(`${name}`, {
                           required: name !== 'subtheme',
                           onChange: changeHandle
                       })}
                />
                <label htmlFor={`radio-${name}-${item._id}`}>{item.label.fr}</label>
            </div>
        });
    }, []);

    const renderSubthemes = useCallback(() => {
        if (!themeId) {
            return null;
        }

        const subtheme = subthemes.find((e) => e.theme_id === themeId);

        if (!subtheme) {
            return null;
        }

        return <div>
            <div>Sous thème</div>
            {renderRadioInputs('subtheme', subtheme.list)}
        </div>;
    }, [themeId, subthemes])

    return <div>
        <form onSubmit={handleSubmit(onSubmit)}>
            {renderLabelsInput()}
            <div>
                <div>Theme</div>
                {renderRadioInputs('theme', themes, themeChangeHandle)}
            </div>

            {renderSubthemes()}

            <div>
                <div>Types</div>
                {renderRadioInputs('type', types)}
            </div>

            <div>
                <div>Traduction</div>
                <div>
                    <input placeholder={"Word"}     type="text" {...register('translation.word', {required: true})} />
                    <input placeholder={"Hiragana"} type="text" {...register('translation.hiragana', {required: false})}/>
                    <input placeholder={"Romaji"}   type="text" {...register('translation.romaji', {required: true})}/>
                </div>
            </div>

            <input type="submit" value={"balance"}/>
        </form>
    </div>;
}

export default AddWord;
