import {useCallback, useContext, useState} from "react";
import {DictionaryContext} from "../../contexts/dictionary";

import "./filter.scss";
import {drawAscii} from "../../helpers/drawAscii";
import {asciiBank} from "../../constants/asciibank";
import HeaderSelect from "../header-select/header-select";
import SubThemesSelect from "../subthemes-select/subthemes-select";
import {FILTER_STATES} from "../../constants/filter-states";
import TypeSelect from "../type-select/type-select";

const Filters = () => {
    const {state} = useContext(DictionaryContext);

    const [filterState, setFilterState] = useState(FILTER_STATES.NONE);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        drawAscii(asciiBank.CARAPUCE_ZENIGAME);
    }, []);

    return <form className={"filters"} onSubmit={handleSubmit}>
        {[FILTER_STATES.NONE, FILTER_STATES.THEMES].includes(filterState) && <HeaderSelect
            toggleState={() => setFilterState(FILTER_STATES.THEMES)}
            resetState={() => setFilterState(FILTER_STATES.NONE)}
        />}
        {[FILTER_STATES.NONE, FILTER_STATES.SUBTHEME].includes(filterState) && <SubThemesSelect
            toggleState={() => setFilterState(FILTER_STATES.SUBTHEME)}
            resetState={() => setFilterState(FILTER_STATES.NONE)}
        />}

        <TypeSelect />
    </form>;
};

export default Filters;
