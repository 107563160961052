import React, {useCallback, useContext, useEffect, useState} from "react";

import "./header.scss";
import Input from "../input/input";
import HeaderSelect from "../header-select/header-select";
import {Link} from "react-router-dom";
import Alphabet from "../alphabet/alphabet";
import {DictionaryContext} from "../../contexts/dictionary";
import ActionButton from "../actionButtons/actionButton";
const Header = () => {
    const {
        state: {search},
        updateInputSearch
    } = useContext(DictionaryContext);

    const renderTopHeader = useCallback(() => {

        const inputOptions = {
            type: 'text',
            placeholder: 'What are you looking for ?',
            icon: 'search',
            defaultValue: search.inputValue,
            handle: (e) => {
                e.preventDefault();
                updateInputSearch(e.target.value);
            }
        }
        const actionButtonHandle = () => {

        };

        return (
            <div className={"header__top"}>
                <div>
                    <h1><Link to={'/'}>日本語<span>の</span>辞書</Link></h1>
                    <div className={"header__input"}>
                        <Input options={inputOptions} />
                    </div>
                </div>
                <div>
                    <ActionButton icon={'options'} />
                </div>
            </div>
        )
    }, []);

    return (
        <header className={"header"}>
            {renderTopHeader()}
            <Alphabet/>
        </header>
    );
};

export default Header;
