import React, {useCallback, useContext, useEffect, useState} from 'react';

import './content.scss';
import Words from "../words/words";
import {DictionaryContext} from "../../contexts/dictionary";
import {sortWordsArray} from "../../helpers/sortWordsArray";
import Filters from "../filters/filters";

const ContentElement = ({children, active, handle}) => {
    const handleDbClick = (e) => {
        e.preventDefault();
        handle();
    }

    return <div className={`content__element ${active ? 'full' : ''}`}>
        <div className={`content__element__${active ? 'hitten-detector' : 'hit-detector'}`}
             onDoubleClick={handleDbClick}
        />
        {children}
    </div>
};
const Content = () => {
    const {state: {data, search: {theme, subtheme, letter, wordType, inputValue}}} =
        useContext(DictionaryContext);
    const [loading, setLoading] = useState(false);
    const [showWords, setShowWords] = useState(true);
    const [words, setWords] = useState([]);

    // When theme or letter changes
    useEffect(() => {
        setLoading(true);
        setShowWords(false);
    }, [theme, letter]);

    // When loading is set to true
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setShowWords(true);
            }, 300);

            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [loading]);

    // When reducer words has been loaded
    useEffect(() => {
        if (!!data.words.length && !words.length) {
            setWords(sortWordsArray(data.words));
        }
    }, [data.words]);

    // When reducer theme, letter or inputValue has been changed
    useEffect(() => {
        // Filter theme
        let newWords = sortWordsArray(theme._id === '' ? data.words :
            data.words.filter((w) => w.theme === theme._id));

        // Filter by subtheme chosen
        newWords = sortWordsArray(subtheme === '' ? newWords :
            newWords.filter(w => w.subtheme === subtheme));

        // Filter by type chosen
        newWords = sortWordsArray(wordType === '' ? newWords :
            newWords.filter(w => w.type === wordType));

        // Filter by letter chosen
        newWords = sortWordsArray(letter === '' ? newWords :
            newWords.filter((w) => (w.label.fr).charAt(0).toLowerCase() === letter));

        // Filter by input typed
        newWords = sortWordsArray(inputValue === '' ? newWords :
            newWords.filter((w) => w.label.fr.includes(inputValue)));

        // Apply final array
        setWords(newWords);
    }, [theme, subtheme, wordType, letter, inputValue]);

    const [leftPanelActive, setLeftPanelActive] = useState(false);

    return <div className={"content"}>
        <ContentElement active={leftPanelActive} handle={() => setLeftPanelActive(true)}>
            <Filters />
        </ContentElement>
        <ContentElement active={!leftPanelActive} handle={() => setLeftPanelActive(false)}>
            <div className={`content__children ${!showWords ? 'hide' : ''}`}>
                <Words words={words}/>
            </div>
        </ContentElement>
    </div>;
}

export default Content;
