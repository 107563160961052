import {DictionaryContext} from "../../contexts/dictionary";
import {useCallback, useContext, useMemo} from "react";

import './subthemes-select.scss';

const SubThemesSelect = () => {
    const {state: {search, data: {subthemes}}, updateSubtheme} = useContext(DictionaryContext);

    const subtheme = useMemo(() => subthemes.find((s) =>
        s.theme_id === search.theme._id), []);

    const handle = useCallback((id) => {
        console.log(id === search.subtheme, id, search.subtheme)
        updateSubtheme(id === search.subtheme ? '' : id);
    }, [search.subtheme]);

    const renderSubthemes = useCallback(() => {
        return subtheme.list.map((l) =>
            <div key={`subtheme-${l.type}-${l._id}`}
                 className={`subtheme__item ${search.subtheme === l._id ? 'subtheme--selected' : ''}`}
                 onClick={() => handle(l._id)}
            >
                {l.label.fr}
            </div>);
    }, [search.subtheme]);

    return <div className={`subtheme ${subtheme ? 'subtheme--open' : ''}`}>
        {subtheme && renderSubthemes()}
    </div>;
};

export default SubThemesSelect;
