import {DictionaryContext} from "../../contexts/dictionary";
import {useCallback, useContext} from "react";

import './type-select.scss';

const TypeSelect = () => {
    const {state: {search, data: {types}}, updateType} = useContext(DictionaryContext);

    const handle = useCallback((id) =>
        updateType(search.wordType === id ? '' : id), [search.wordType]);

    const renderTypes = useCallback(() => {
        return types.map((t) =>
           <div key={`type-${t._id}`}
                className={`type-select__item ${search.wordType === t._id ? 'type-select--selected' : ''}`}
                onClick={() => handle(t._id)}
           >{t.label.fr}</div>
        );
    }, [types, search.wordType])

    return <div className={"type-select"}>
        <div className={"type-select__choices"}>
            {renderTypes()}
        </div>
    </div>;
};

export default TypeSelect;
