import {Link} from "react-router-dom";
import {useCallback} from "react";

const ActionButton = ({icon, callback}) => {
    const handleClick = useCallback((e) => {
        e.preventDefault();
        callback();
    }, []);

    return <a href={"#"} onClick={handleClick}>
        <i className={`gg-${icon}`} />
    </a>;
};

export default ActionButton;
