import {useCallback} from "react";

import "./words.scss";

const Words = ({words}) => {
    const renderWords = useCallback(() => {
        if (!words.length) {
            return null;
        }

        return words.map((w) => {
           return <div key={`word-${w._id}`} className={"words__item"}>
               <div>{w.label.fr}</div>
               <div>{w.translation.word} {w.translation.hiragana && `「${w.translation.hiragana}」`}</div>
           </div>
        });
    }, [words]);

    return (
        <div className={"words"}>
            {renderWords()}
        </div>
    )
}

export default Words;
